/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import { faArrowRight, faArrowTurnDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { animateScroll } from 'react-scroll'
import { useDispatch } from 'react-redux'
import constants, { allPopularSkills, capitalise, popularSkillsFromCategories } from '../../utilities/constants'
import { setSavedResponse, setShowCourseLoader, setUTMDetails, setUserSkillName, setVisitedAnalysisPage } from '../../redux/skillSlice'
import { toast } from 'react-toastify'
import { Mixpanel } from '../../config/Mixpanel'
import useWindowSize from '../../hooks/useWindowSize'
import { resetAnalysis } from '../../redux/analysisSlice'
import { resetQuestionAnswer } from '../../redux/questionAnswerSlice'
import { resetProjectsData } from '../../redux/projectSlice'
import { resetCourseData } from '../../redux/coursesSlice'
import { resetXanoData } from '../../redux/beforePaymentSlice'
import { setAnalysisPageButtonClicked, setLastVisitedUrl } from '../../redux/nameSlice'
import { resetCurationStatus } from '../../redux/curatedCourseSlice'
import { resetQuestions } from '../../redux/questionsSlice'
import { resetSavedDetails } from '../../redux/savedCourseSlice'
import { fetchPopularSkills } from '../../redux/searchPopularSkillSlice'
import useDebounce from '../../hooks/useDebounce'
import SearchSkeleton from '../../components/SearchSkeleton'
import Logos from '../../components/Logos'
import { resetQuestionList } from '../../redux/goalDetailsSlice'
import Lottie from 'react-lottie'
import animation from './Startup Launch.json'

const LottieAnimation = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }
    return <Lottie options={defaultOptions} />;
}


const LandingPageV2= () => {

    
    const location = useLocation()

    const params = new URLSearchParams(location.search)
    const utm_campaign = params.get('utm_campaign')
    const utm_medium = params.get('utm_medium')
    const skill_slug = params.get('skill_slug')
    const course_slug = params.get('course_slug')
    const type = params.get('type')

    const inputRef = useRef(null)
    const dispatch = useDispatch()
    const { height } = useWindowSize()
    
    const [goalValue, setGoalValue] = useState('')
    const [popularSkills, setPopularSkills] = useState([])
    const [selectedSkill, setSelectedSkill] = useState([])
    const [ searchResult, setSearchResult ] = useState([])
    const [ scrollToTop, setScrollToTop] = useState(false)
    const [showTitle, setShowTitle] = useState(true)
    const { debouncedValue } = useDebounce(goalValue)

    const navigate = useNavigate()

    useEffect(()=>{
        Mixpanel.track(constants.Mixpanel.LANDING_PAGE)

        localStorage.setItem('showScholarship', JSON.stringify({}))
        localStorage.setItem('visitedAnalysisPage', JSON.stringify(false))
        dispatch(resetQuestions())
        dispatch(resetAnalysis())
        dispatch(resetQuestionAnswer())
        dispatch(resetProjectsData())
        dispatch(resetCourseData())
        dispatch(resetXanoData())
        dispatch(setAnalysisPageButtonClicked(false))
        dispatch(resetCurationStatus())
        dispatch(resetSavedDetails())
        dispatch(resetQuestionList())
        dispatch(setUTMDetails({ utm_source: "Workindia", utm_medium: utm_medium ? utm_medium : "", utm_campaign: utm_campaign ? utm_campaign : "", type: type || "" }))
        dispatch(setShowCourseLoader(false))
        dispatch(setVisitedAnalysisPage(false))
        dispatch(setSavedResponse(false))
        dispatch(setLastVisitedUrl('/'))
        if (skill_slug){
            const fetchData = async ()=> {
                try {
                    await setGoalValue(skill_slug)
                    const res = await dispatch(fetchPopularSkills({ searchValue: skill_slug}))
                    setPopularSkills(res?.payload)
                } catch (error) {
                    console.log(error)
                }
            }
            fetchData()
        }

        if (course_slug){
            setPopularSkills(popularSkillsFromCategories.hasOwnProperty(course_slug) ? popularSkillsFromCategories[course_slug]?.skills : allPopularSkills)
            setSelectedSkill(Array(popularSkillsFromCategories.hasOwnProperty(course_slug) ? popularSkillsFromCategories[course_slug]?.skills?.length : allPopularSkills.length).fill(false))
        }

        if (!skill_slug && !course_slug){
            if(inputRef.current){
                inputRef.current.focus()
            }
            setPopularSkills(allPopularSkills)
        }
        
    }, [])

    useEffect(() => {
        if(!showTitle && goalValue.length > 0 && !scrollToTop){
            setScrollToTop(true)
        }
    }, [goalValue, showTitle])

    useEffect(() => {
        if(scrollToTop){
            animateScroll.scrollMore(height <= 740 ? 80 : 250, { duration: 300 })
        }else{
            animateScroll.scrollToTop({ duration: 300 })
        }
    }, [scrollToTop])

    useEffect(() => {
        if(debouncedValue){
            const fetchData = async() => {
                try {
                    const res = await dispatch(fetchPopularSkills({ searchValue: debouncedValue }))
                    if(res?.payload?.length > 0){
                        setSearchResult(res?.payload)
                    }else{
                        setSearchResult([debouncedValue])
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            fetchData()
        }else{
            setSearchResult([])
        }
    }, [debouncedValue])

    const handleSubmit = (e) => {
        e.stopPropagation()
        e.preventDefault()
        if (goalValue === '') {
            toast.error('Type a skill you want to learn')
            return
        }
        const skillName = capitalise(goalValue)
        Mixpanel.people.set({
            'skillName': skillName
        })
        dispatch(setUserSkillName({ skillName }))
        Mixpanel.track(constants.Mixpanel.SKILL_NAME)
        navigate('/onboarding')
    }

    const handleSkillSelection = (index) => {
        setShowTitle(true)
        setScrollToTop(false)
        const tempArray = [...Array(selectedSkill.length).fill(false)]
        tempArray[index] = true
        setSelectedSkill(tempArray)
        setGoalValue(popularSkills[index])
    }

    const handleDropResults = (index) => {
        if (inputRef.current){
            inputRef.current.focus()
        }
        setShowTitle(false)
        setGoalValue(searchResult[index])
    }

    console.log(showTitle)
    
return (
    
        <div className='max-w-[720px] flex-1 mx-auto px-4 pb-4 lg:pb-6 lg:px-6 text-white relative text-center flex flex-col gap-2' onClick={(e) => {
            setShowTitle(true)
            setScrollToTop(false)
        }}>
            <div className='flex justify-between mt-4 md:mt-6'>
                <Logos />
            </div>
            {height > 740 && <div className={`w-[258px] mx-auto h-[154px] ${goalValue ? 'mt-6' : 'mt-4'}`}>
                <LottieAnimation/>
            </div>}
            <div className={`${goalValue ? 'mt-4' : 'mt-2'}`}>
                <h1 className={`text-[38px] lg:text-[44px] pb-2 leading-9`}>Get Your Dream Job <span className='text-[#00C1FF] font-bold'>3x Faster</span></h1>
                <p className='lg:text-2xl opacity-80 leading-4'>With a Personalised Course Made Specially For You</p>
            </div>
            <div className={`flex-1 flex flex-col ${goalValue ? 'mt-6' : 'justify-center'}`}>
                <h2 className='text-[22px] lg:text-[24px] text-left font-bold'>What course do you want?</h2>
                <form onSubmit={handleSubmit}>
                    <div className='relative'>
                        <input type="text" 
                            ref={inputRef}
                            className='bg-[#2D4F71] text-white rounded-lg py-2 h-[44px] pl-4 pr-12 mt-2 w-full mx-auto user_goal box-glow'
                            placeholder='Type any topic you want to learn'
                            value={goalValue}
                            onFocus={() => {
                                setShowTitle(false)
                            }}
                            onChange={(e) => {
                                if (showTitle) {
                                    setShowTitle(false)
                                }
                                setGoalValue(e.target.value)
                            }}
                        />
                        {goalValue && <FontAwesomeIcon icon={faArrowTurnDown} onClick={handleSubmit} className='text-[#7FC241] text-xl absolute right-5 z-10 bottom-1.5 rotate-90 cursor-pointer'/>}
                    </div>
                    {(goalValue && showTitle) && <div className='flex justify-between items-center mt-5'>
                        <button className={`bg-[#7FC241] h-[44px] flex items-center justify-center text-black font-semibold rounded-xl py-2 px-4 w-1/2 lg:w-1/3 cursor-pointer leading-4`}>Get Started <span><FontAwesomeIcon icon={faArrowRight} className='text-xl ml-4 mt-1 arrow-move'/></span></button>
                        <p className=' text-sm tracking-wide font-normal opacity-70'>Takes just 5 min</p>
                    </div>}
                </form>
                {(!showTitle && goalValue) && <div className=' mt-4 rounded-lg' 
                    style={{ boxShadow: '0px 0px 7px 0px #7FC241' }}
                >
                    {searchResult.length <= 0 ? <div className='h-[226px] w-full rounded-lg'>
                        <SearchSkeleton/>
                    </div> : <div className={` ${searchResult?.length > 5 ? 'takeaway' : ''} rounded-lg border border-[#7FC241] bg-[#2D4F71] h-[226px] overflow-y-scroll`} >
                        {searchResult?.map((item, index) => (
                            <div key={index} onClick={() => handleDropResults(index)} className={`text-left ${goalValue === item ? 'bg-[#2B71BA]' : '' } px-3 py-2.5 border-b border-[#3572AF]`}>
                                {item}
                            </div>
                        ))}
                    </div>}
                </div>}
                {!goalValue && <>
                    {!goalValue && <div className={`flex justify-between items-center mt-4 mb-2 text-[#90BEED]`}>
                        <p>Popular Topics</p>
                        <Link to='/course-page'><p className='underline text-xs'>Explore By Category?</p></Link>
                    </div>}
                    <div className={`${(height <= 740 && goalValue) ? 'h-[85px]' : height <= 740 ? 'h-[128px]' : goalValue ? 'h-[128px] mt-4' : 'h-[170px]'} overflow-y-scroll ${popularSkills?.length < 3 ? '' : 'takeaway'}`}>
                        <div className='flex gap-2 flex-wrap'>
                            {popularSkills.map((item, index) => (
                                <div key={index} className={`${goalValue === item ? 'bg-[#316EAB] border border-[#7FC241]' : 'bg-[#2D4F71] text-[#9ABFE5]'} py-2 px-3 cursor-pointer rounded-lg text-sm h-fit`} onClick={(e) => {
                                    e.stopPropagation()
                                    handleSkillSelection(index)
                                }}>
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                </>}
            </div>
            <div className='w-full lg:w-3/5 mx-auto'>
                <p className='font-semibold tracking-wide text-base mb-0.5'>We Build Life-Changing Careers</p>
                <div className='bg-[#2D4F71] flex justify-between py-3.5 px-5 rounded-xl'>
                    <div className='text-center'>
                        <h2 className='text-2xl font-bold'>10<span className='text-sm font-medium'>Lakh+</span></h2>
                        <p className='text-xs text-[#9ABFE5] font-medium'>Active Users</p>
                    </div>
                    <div className='text-center'>
                        <h2 className='text-2xl font-bold'>4.8/5</h2>
                        <p className='text-xs font-medium text-[#9ABFE5]'>Course Rating</p>
                    </div>
                    <div className='text-center'>
                        <h2 className='text-2xl font-bold'>34<span className='text-sm font-medium'>Lakh</span></h2>
                        <p className='text-xs font-medium text-[#9ABFE5]'>Highest Package</p>
                    </div>
                </div>
            </div>
        </div>
    
  )
}

export default LandingPageV2