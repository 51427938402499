import { Outlet } from "react-router-dom"
import React, {useEffect} from 'react'
import { ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import { animateScroll as scroll } from 'react-scroll'
import LanguageModal from "../../components/LanguageModal"
import { selectShowLanguageModal } from "../../redux/goalDetailsSlice"
import { useSelector } from "react-redux"

const Layout = () => {

    const showLanguageModal = useSelector(selectShowLanguageModal)

    useEffect(() => {
        scroll.scrollToTop();
    }, []);
  
    
    return (
        <div  className={`w-full mx-auto h-[100dvh] flex flex-col overflow-y-scroll`} style={{background: 'radial-gradient(50% 50% at 50% 50%, #26496A 0%, #182C3F 100%)'}}>
            <ToastContainer />
            <Outlet />
            {showLanguageModal && <LanguageModal
                isOpen={showLanguageModal} 
            />}
        </div>
    )
}

export default Layout