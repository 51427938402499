import { Dialog } from '@headlessui/react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { setShowLanguageModal } from '../redux/goalDetailsSlice'

const LanguageModal = ({ isOpen }) => {

    const dispatch = useDispatch()

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={() => dispatch(setShowLanguageModal(false))}
                as="div"
                className="fixed inset-0 z-10 flex my-auto flex-col items-center"
            >
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-70 z-10 my-auto" />
                <Dialog.Title/>
                <Dialog.Description/>
                <div className='z-50 bg-secondary_background w-[90%] text-white my-auto rounded-xl p-4'>
                    <h3 className='text-2xl font-semibold text-center'>Settings</h3>
                    <div className='mt-4'>
                        <div className='flex items-center gap-2 pb-4 border-b border-[#3069A3]'>
                            <img src="https://ik.imagekit.io/238r9emirjj/certified%20app/Translate%20language.png?updatedAt=1710160073386" width={24} height={24} alt="" />
                            <h3 className='text-xl font-medium'>Change Language</h3>
                        </div>
                        <div className='flex items-center gap-2 mt-4'>
                            <img src="https://ik.imagekit.io/238r9emirjj/certified%20app/Change.png?updatedAt=1710160357688" width={24} height={24} alt="" />
                            <h3 className='text-xl font-medium'>Change Skill</h3>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default LanguageModal