import { lazy, Suspense, useLayoutEffect } from 'react'
import { Routes, Route, useLocation} from 'react-router-dom'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Loader from './components/Loader'
import Layout from './pages/Layouts/Layout'
import LayoutTwo from './pages/Layouts/LayoutTwo'
import Welcome from './components/Welcome'
import PrivateRoute from './config/private-route'
import LandingPageV2 from './pages/LandingPage/LandingPageV2'
import { animateScroll } from 'react-scroll'
import * as Sentry from "@sentry/react";
import FallbackUI from './components/FallbackUI'

const NewCoursePage = lazy(() => import('./pages/CourseSection/NewCoursePage'))
const NewAnalysisPage = lazy(() => import('./pages/Analysis/NewAnalysisPage'))
const TopicKnows = lazy(() => import('./pages/OnBoarding/TopicKnows'))
const UserBackground = lazy(() => import('./pages/OnBoarding/UserBackground'))
const UserGoal = lazy(() => import('./pages/OnBoarding/UserGoal'))
const GoalDetails = lazy(() => import('./pages/OnBoarding/GoalDetails'))
const SkillLevel = lazy(() => import('./pages/SkillLevel'))
const SkillList = lazy(() => import('./pages/LandingPage/SkillList'))
const CourseList = lazy(() => import('./pages/LandingPage/CourseList'))
const OnBoardingPage = lazy(() => import(/* webpackPrefetch: true */ './pages/OnBoarding'))
const QuestionarySection = lazy(() => import('./pages/QuestionarySection'))
const Register = lazy(()=> import(/* webpackPrefetch: true */ './pages/Register'))
const PricingVersion2 = lazy(()=> import(/* webpackPrefetch: true */ './pages/PricingVersion2'))
const ThankYou = lazy(() => import('./pages/Pricing/ThankYou'))
const PageNotFound = lazy(() => import('./components/PageNotFound'))
const LessonPage = lazy(() => import('./pages/Lessons'))
const ProjectsPage = lazy(() => import('./pages/Projects'))
const CourseListPage = lazy(() => import('./pages/CourseList'))
const CurationLoader = lazy(() => import('./components/CurationLoader'))

function App() {

    const { pathname } = useLocation()

    useLayoutEffect(()=>{
        animateScroll.scrollToTop({ duration: 300 })
    }, [pathname])


    return (
        <Sentry.ErrorBoundary fallback={({ resetError }) => <FallbackUI resetError={resetError}/>}>
            <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<LandingPageV2/>} />
                        <Route path='course-page' element={<Suspense fallback={<Loader/>}><CourseList/></Suspense>} />
                        <Route path='skill-level' element={<Suspense fallback={<Loader/>}><SkillLevel/></Suspense>} />
                        <Route path='skill-page' element={<Suspense fallback={<Loader/>}><SkillList/></Suspense>} />
                        <Route path='onboarding'>
                            <Route index element={<Suspense fallback={<Loader/>}><OnBoardingPage/></Suspense>} />
                            <Route path='user-background' element={<Suspense fallback={<Loader/>}><UserBackground/></Suspense>} />
                            <Route path='user-goal' element={<Suspense fallback={<Loader/>}><UserGoal /></Suspense>} />
                            <Route path='goal-details' element={<Suspense fallback={<Loader/>}><GoalDetails /></Suspense>} />
                            <Route path='questions/:id' element={<Suspense fallback={<Loader/>}><QuestionarySection/></Suspense>}/>
                            <Route path='register' element={<Suspense fallback={<Loader/>}><Register/></Suspense>}/>
                        </Route>
                        <Route path='analysis' element={<Suspense fallback={<Loader/>}><NewAnalysisPage/></Suspense>}/>
                        <Route path='courses' element={<Suspense fallback={<Loader/>}><NewCoursePage /></Suspense>} />
                        <Route path= 'pricing'>
                            <Route path='v2' element={<Suspense fallback={<Loader/>}><PricingVersion2/></Suspense>}/>
                            <Route path='loading' element={<Suspense fallback={<Loader/>}><CurationLoader/></Suspense>}/>
                            <Route path='thank-you' element={<Suspense fallback={<Loader/>}><ThankYou /></Suspense>} />
                        </Route>
                    </Route>
                    <Route path='/onboarding/topic-know' element={<Suspense fallback={<Loader/>}><TopicKnows /></Suspense>}/>
                    <Route path='/' element={<LayoutTwo/>}>
                        <Route path='welcome' element={<Welcome/>}/>
                        <Route path=':course_slug' element={<PrivateRoute><Suspense fallback={<Loader/>}><CourseListPage/></Suspense></PrivateRoute>}/>
                        <Route path=':course_slug/:module_slug/:lesson_slug' element={<PrivateRoute><Suspense fallback={<Loader/>}><LessonPage/></Suspense></PrivateRoute>}/>
                        <Route path=':course_slug/:project' element={<PrivateRoute><Suspense fallback={<Loader/>}><ProjectsPage/></Suspense></PrivateRoute>}/>
                        <Route path= '*' element={<Suspense fallback={<Loader/>}><PageNotFound/></Suspense>} />
                    </Route>
            </Routes>
        </Sentry.ErrorBoundary>
    )
}

export default App;
