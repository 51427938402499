import React from 'react'

const LogosVersion2 = () => {
  
  return (
    <div className='max-w-[211px] h-full bg-white text-black flex items-center rounded-md'>
        <img src="https://ik.imagekit.io/238r9emirjj/Workindia/header-logo.png?updatedAt=1709650004585" alt="" className='w-full h-auto object-cover' />
    </div>
  )
}

export default LogosVersion2