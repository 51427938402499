import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const sendUserGoalDetails = createAsyncThunk(
    'sendUserGoalDetails',
    async ({ uuid, user_data, user_name }) => {
        try {
            const response = await axios.post('https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/save_user_course_specific_data', {
                uuid, user_data, user_name
            })

            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const getTopicsList = createAsyncThunk(
    'getTopicsList',
    async ({ subject, goal }) => {
        try {
            const response = await axios.post('https://ninjacurate.learntube.ai/course_concepts_list', {
                subject, goal
            })
            return response.data
        } catch (error) {
            console.log(error.message)
            throw error
        }
    }
)

export const fetchQuestions = createAsyncThunk(
    'fetchQuestions',
    async ({ subject, concepts, uuid }) => {
        try {
            const response = await axios.post('https://certified-backend.learntube.ai/concept_based_questions', {
                subject, concepts, uuid
            })
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

const initialState = {
    professionalExperience: '',
    showLanguageModal: false,
    careerGoal: '',
    careerGoalImage: '',
    desiredJob: '',
    currentJob: '',
    currentDegree: '',
    skillWantToLearn: '',
    topicsList: [],
    questionList: [],
    topicKnows: [],
    skipQuiz: false
}

const goalDetailsSlice = createSlice({
    name: 'goalDetails',
    initialState: initialState,
    reducers: {
        setUserProfessionalExperience: (state, action) => {
            state.professionalExperience = action.payload
        },
        setShowLanguageModal: (state, action) => {
            state.showLanguageModal = action.payload
        },
        setCareerGoal: (state, action) => {
            const { careerGoalImage, careerGoal } = action.payload
            state.careerGoal = careerGoal
            state.careerGoalImage = careerGoalImage
        },
        setDesiredJob: (state, action) => {
            state.desiredJob = action.payload
        },
        setCurrentJob: (state, action) => {
            state.currentJob = action.payload
        },
        setUserCurrentDegree: (state, action) => {
            state.currentDegree = action.payload
        },
        setUserSkillToLearn: (state, action) => {
            state.skillWantToLearn = action.payload
        },
        setTopicKnows: (state, action) => {
            state.topicKnows = action.payload
        },
        setSkipQuiz: (state, action) => {
            state.skipQuiz = action.payload
        },
        resetQuestionList: (state) => {
            state.questionList = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTopicsList.fulfilled, (state, action) => {
            state.topicsList = action.payload?.concepts
        })
        builder.addCase(fetchQuestions.fulfilled, (state, action) => {
            state.questionList = action.payload?.questionaire
        })
    }
})

export const { setUserProfessionalExperience, setShowLanguageModal, setCareerGoal, setDesiredJob, setCurrentJob, setUserCurrentDegree, setUserSkillToLearn, resetQuestionList, setTopicKnows, setSkipQuiz } = goalDetailsSlice.actions
export const selectShowLanguageModal = (state) => state.goalDetails.showLanguageModal
export const selectCareerGoal = (state) => state.goalDetails.careerGoal
export const selectCareerGoalImage = (state) => state.goalDetails.careerGoalImage
export const selectProfessionalExperience = (state) => state.goalDetails.professionalExperience
export const selectDesiredJob = (state) => state.goalDetails.desiredJob
export const selectCurrentJob = (state) => state.goalDetails.currentJob
export const selectCurrentDegree = (state) => state.goalDetails.currentDegree
export const selectSkillToLearn = (state) => state.goalDetails.skillWantToLearn
export const selectTopicsList = (state) => state.goalDetails.topicsList
export const selecteMCQuestions = (state) => state.goalDetails.questionList
export const selectTopicKnows = (state) => state.goalDetails.topicKnows
export const selectSkipQuiz = (state) => state.goalDetails.skipQuiz
export default goalDetailsSlice.reducer